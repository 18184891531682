import React, { useState } from "react"
import LinkButton from 'src/components/link_button'
import styles from 'src/css/slices/phone_hero_carousel.module.css'
import { Carousel } from 'src/components/react-responsive-carousel'
import { RichText } from 'prismic-reactjs'
import { coalesceRichText } from 'src/utilities/string'
import Link from 'src/components/link'
import Image from 'src/components/image'
import AppDownload from 'src/components/app_badges'
import CrossFade, { CrossFadeItem } from 'src/components/cross_fade'
import PhoneTemplateUrl from 'src/images/phone-template.png'
import { classes } from 'src/utilities/classes'
import { richTextEmpty } from "../utilities/string"

const PhoneHeroCarousel = ({ primary, fields, appBadges }) => {
  const {
    header_text,
    interval,
    swipe_tolerance,
    swipeable,
    auto_play,
    left_button_text,
    left_button_link,
    right_button_text,
    right_button_link,
    left_mobile_button_text,
    left_mobile_button_link,
    right_mobile_button_text,
    right_mobile_button_link,
    phone_label,
    disclaimer,
    mobile_disclaimer
  } = primary
  const carouselInterval = (interval || 5000)
  const carouselAutoPlay = (auto_play == null) ? true : auto_play
  const carouselSwipeable = (swipeable == null) ? true : swipeable
  const carouselSwipeTolerance = (swipe_tolerance || 50)

  const [backgroundIndex, setBackgroundIndex] = useState(0)

  const {
    slide_header_text,
    slide_left_button_text,
    slide_left_button_link,
    slide_right_button_text,
    slide_right_button_link
  } = (fields || [])[backgroundIndex]

  const headerText = coalesceRichText(slide_header_text, header_text)

  return (
    <div className={styles.root}>
      <div className={styles.carouselWrapper}>
        <CrossFade className={styles.background} active={backgroundIndex}>
          {fields.map(({ background_image, background_color, mobile_background_image }, i) => (
            <CrossFadeItem
              className={styles.slideBackground}
              style={{
                backgroundColor: background_color,
                backgroundImage: `url("${background_image?.url}")`,
                "--mobile-background": `url("${mobile_background_image?.url || background_image?.url}")`
              }}
              name={i}
            />
          ))}
        </CrossFade>

        <div className={styles.heroContent}>
          <RichText render={headerText} />
          <div className={styles.desktopCta}>
            <LinkButton to={slide_left_button_link || left_button_link} size="lg">{slide_left_button_text || left_button_text}</LinkButton>
            <LinkButton to={slide_right_button_link || right_button_link} size="lg">{slide_right_button_text || right_button_text}</LinkButton>
          </div>
          {!richTextEmpty(disclaimer) && <div className={styles.disclaimer}>
            <RichText render={disclaimer} />
          </div>}
        </div>

        <div className={styles.phoneContainer}>
          <div className={styles.phone}>
            <div className={styles.phoneScreen}>
              <Carousel
                showIndicators={false}
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                selectedItem={backgroundIndex}
                onChange={(index) => (backgroundIndex !== index && setBackgroundIndex(index))}
                autoPlay={carouselAutoPlay}
                swipeable={carouselSwipeable}
                interval={carouselInterval}
                swipeScrollTolerance={carouselSwipeTolerance}
                preventMovementUntilSwipeScrollTolerance={true}
                width={'100%'}
              >
                {fields.map(({ phone_image }, i) => {
                  return (
                    <div key={i} className={styles.slideContainer}>
                      <img src={phone_image.url} alt={phone_image.alt} className={styles.slideImage} />
                    </div>
                  )
                })}
              </Carousel>
            </div>
            {fields[backgroundIndex]?.image_link ?
              <Link to={fields[backgroundIndex]?.image_link} className={styles.phoneTemplate}>
                <img src={PhoneTemplateUrl} alt="" />
              </Link> :
              <div className={styles.phoneTemplate}>
                <img src={PhoneTemplateUrl} alt="" />
              </div>
            }
            <div className={styles.phoneLabel}>
              {phone_label}
            </div>
            <div className={styles.phoneFooter}>
              <div className={styles.logoContainer}>
                {fields.map(({ logo }, i) => (
                  !!logo?.url && <img key={i} src={logo.url} alt={logo.alt} className={classes(styles.logo, i === backgroundIndex && styles.active)} />
                ))}
              </div>
              <div className={styles.carouselDots}>
                {fields.map((f, i) =>
                  <div key={i} className={i === backgroundIndex ? styles.controlDotActive : styles.controlDot} onClick={() => setBackgroundIndex(i)}>
                    <div></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!richTextEmpty(disclaimer) && <div className={styles.narrowDisclaimer}>
        <RichText render={disclaimer} />
      </div>}
      <div className={styles.mobileCta}>
        {!richTextEmpty(mobile_disclaimer) && <div className={styles.mobileDisclaimer}>
          <RichText render={mobile_disclaimer} />
        </div>}
        <AppDownload className={styles.appDownloads}>
          {(appBadges || []).map(({ badge, link, width, margin }, i) =>
            <Link key={i} to={link} target="_blank">
              <Image style={{ width, margin, height: 'auto' }}>{badge}</Image>
            </Link>
          )}
        </AppDownload>
        <div className={styles.mobileCtaActions}>
          <LinkButton to={left_mobile_button_link} className={styles.mobileCtaButton}>{left_mobile_button_text}</LinkButton>
          <LinkButton to={right_mobile_button_link} className={styles.mobileCtaButton}>{right_mobile_button_text}</LinkButton>
        </div>
      </div>
    </div>
  )
}

export default PhoneHeroCarousel