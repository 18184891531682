import React, {Component} from "react"
import RichText from 'src/components/rich_text'
import Image from 'src/components/image'
import Button from 'src/components/button'
import Link from 'src/components/link'
import styles from 'src/css/slices/noteworthy.module.css'
import DynamicStyle from 'src/components/dynamic_style'

class Noteworthy extends Component {
  render = () => {
    const { primary, fields } = this.props
    const { background_color } = primary
    const backgroundColor = (background_color || '#EDEDED');
    return (
      <div className={styles.noteworthy} style={{ backgroundColor }}>
        <h2>{primary?.heading || 'New & Noteworthy'}</h2>
        <div className={styles.carousel}>
          {fields.map(({image, text, text_color, button_text, button_link, button_color, button_text_color, badge_text}, i) =>
            <div className={styles.shopCardWrapper} key={i}>
              <div className={styles.shopCard} style={badge_text ? { "--shop-card-badge-text": `"${badge_text}"` } : undefined}>
                <Image loading="lazy">{image}</Image>
                <div className={styles.shopCardBody}>
                  <RichText className={styles.shopCardText} style={{ color: text_color }}>{text}</RichText>
                  <Link to={button_link}>
                    <DynamicStyle dynamicStyle={{ '&:not(:hover)': { color: button_text_color }, background: button_color }}>
                      <Button id={`merchants-slice-button-${i}`} withArrow>{button_text}</Button>
                    </DynamicStyle>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Noteworthy