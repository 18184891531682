import { graphql } from "gatsby"
export const Menu = graphql`
  fragment Menu on PRISMIC_MenuConnectionConnection {
    edges {
      node {
        foreground_color
        background_color_start
        background_color_end
        button_link { ...Link }
        button_text
        shop_button_link { ...Link }
        shop_button_text
        sell_button_link { ...Link }
        sell_button_text
        button_text_hover_color
        contact_us_link {
          ...Link
        }
        contact_us_link_text
        footer_image
        footer_text
        footer_text_bottom_left
        help_link {
          ...Link
        }
        help_link_text
        links {
          link {
            ...Link
          }
          link_text
        }
        privacy_link {
          ...Link
        }
        privacy_link_text
        social_links {
          link {
            ...Link
          }
          link_icon
        }
        header_link_color
        header_link_color_home
        header_links {
          link {
            ...Link
          }
          link_text
        }
        log_in_link {
          ...Link
        }
        log_in_link_text
        log_in_link_text_color
        log_in_link_text_color_home
        log_in_link_text_color_menu
        log_in_sign_up_link {
          ...Link
        }
        log_in_sign_up_link_text
        personal_log_in_link_text
        personal_log_in_link {
          ...Link
        }
        merchant_log_in_link_text
        merchant_log_in_link {
          ...Link
        }
        sign_up_link {
          ...Link
        }
        sign_up_link_text
        sign_up_button_color
        sign_up_button_text_color
        sign_up_button_color_home
        sign_up_button_text_color_home
        sign_up_button_color_menu
        sign_up_button_text_color_menu
        personal_sign_up_link_text
        personal_sign_up_link {
          ...Link
        }
        merchant_sign_up_link_text
        merchant_sign_up_link {
          ...Link
        }
        _meta {
          lang
        }
        instagram_header_text
        instagram_header_text_color
        instagram_follow_text
        instagram_follow_text_color
        instagram_follow_link {
          ...Link
        }
        footer_social_link_color
        newsletter_description
        newsletter_header
        footer_shop_today_caption
        get_laybuy_text
        get_laybuy_link {
          ...Link
        }
        footer_site_links {
          site_link_text
          site_link {
            ...Link
          }
        }
        footer_copyright_text
        get_the_app_header
        app_badges {
          badge
          link {
            ...Link
          }
          width
          margin
        }
        legal_text
        body {
          ... on PRISMIC_MenuBodyFooter_link_section {
            type
            primary {
              section_name
            }
            fields {
              link_text
              link {
                ...Link
              }
            }
          }
        }
      }
    }
  }
`