import React, { Component } from 'react'
import Link from 'src/components/link'
import Image from 'src/components/image'
import PageContext from 'src/components/page_context'
import SiteSettingsContext from 'src/components/site_settings_context'
import LocaleSwitcher from 'src/components/locale_switcher'
import styles from 'src/css/layout.module.css'
import LaybuyMono from 'src/images/laybuy_mono'
import Button from 'src/components/button'
import { enableScroll } from 'src/utilities/scrolling'
import { localeName } from 'src/utilities/locale'
import { Facebook, Instagram, LinkedIn, Twitter } from 'src/images/social'
import { classes } from 'src/utilities/classes'
import { alias } from 'src/utilities/locale'
import SiteBanner from './site_banner'
import GlobalButtonStyle from './global_button_style'
import DynamicStyle from 'src/components/dynamic_style'
import NavMenu, { NavItem } from './nav_menu'
import AppBadges from './app_badges'
import { RichText } from 'prismic-reactjs'
import { richTextEmpty } from '../utilities/string'

export default class Layout extends Component {

  state = { localeOpen: false, subscribeAddress: '', siteBannerIndex: 0, openLinkSections: [] }

  constructor(props) {
    super(props)
    this.navRef = React.createRef();
  }

  componentWillUnmount = () => {
    enableScroll()
    document.body.className = ''
  }

  get shouldShowLanguagePrompt() {
    return !this.state.languagePromptDismissed && this.state.userLang && this.state.userLang !== this.props.pageContext.lang
  }

  get languages() {
    const { alternateLanguages, type, uid, lang } = this.props.pageContext
    const currentLang = { type, uid, lang }
    const allLangs = [...alternateLanguages || [], currentLang]
    return allLangs.sort((a, b) => localeName(a.lang).localeCompare(localeName(b.lang)))
  }

  handleDismissLanguagePrompt = () =>
    this.setState({ languagePromptDismissed: true })

  linksOpen = (name,i) => (i === 0 || this.state.openLinkSections?.includes(name))

  handleOpenLinks = name => () => {
    if (!this.state.openLinkSections?.includes(name)) {
      this.setState({ openLinkSections: [...this.state.openLinkSections, name] })
    }
  }

  render = () => {
    const { children, pageContext, menuData = {}, siteBannerData, siteSettings, home, invertedFooter } = this.props
    const {
      body: footerLinkSections,
      social_links: socialLinks,
      log_in_link_text,
      log_in_link_text_color,
      log_in_link_text_color_menu,
      log_in_link_text_color_home,
      personal_log_in_link_text,
      personal_log_in_link,
      merchant_log_in_link_text,
      merchant_log_in_link,
      sign_up_link_text,
      personal_sign_up_link_text,
      personal_sign_up_link,
      merchant_sign_up_link_text,
      merchant_sign_up_link,
      sign_up_button_color,
      sign_up_button_text_color,
      sign_up_button_color_home,
      sign_up_button_text_color_home,
      sign_up_button_color_menu,
      sign_up_button_text_color_menu,
      footer_site_links,
      footer_copyright_text,
      app_badges,
      legal_text
    } = menuData

    return (
      <PageContext.Provider value={pageContext}>
        <SiteSettingsContext.Provider value={siteSettings}>
          <GlobalButtonStyle siteSettings={siteSettings} />
          <DynamicStyle className="nav-login-link" dynamicStyle={{
            '&&': {
              color: log_in_link_text_color,
              '& .transparent': {
                color: log_in_link_text_color_home
              },
              '& .expanded': {
                color: log_in_link_text_color_menu
              }
            }
          }} />
          <DynamicStyle className="nav-signup-link" dynamicStyle={{
            '&&': {
              color: sign_up_button_text_color,
              background: sign_up_button_color,
              '& .transparent': {
                color: sign_up_button_text_color_home,
                background: sign_up_button_color_home,
              },
              '& .expanded': {
                color: sign_up_button_text_color_menu,
                background: sign_up_button_color_menu,
              }
            }
          }} />

          <NavMenu
            transparent={home}
            siteBanner={<SiteBanner siteBannerData={siteBannerData} />}
            logoLink={<Link to={{ type: 'homepage' }} />}
            login={
              <NavItem button label={log_in_link_text} className="nav-login-link">
                {personal_log_in_link_text && <NavItem component={Link} label={personal_log_in_link_text} to={personal_log_in_link} arrow />}
                {merchant_log_in_link_text && <NavItem component={Link} label={merchant_log_in_link_text} to={merchant_log_in_link} arrow />}
              </NavItem>
            }
            signup={
              <NavItem button label={sign_up_link_text} className="nav-signup-link">
                {personal_sign_up_link_text && <NavItem component={Link} label={personal_sign_up_link_text} to={personal_sign_up_link} arrow />}
                {merchant_sign_up_link_text && <NavItem component={Link} label={merchant_sign_up_link_text} to={merchant_sign_up_link} arrow />}
              </NavItem>
            }
            mobileMenuFooter={
              <AppBadges atBottom>
                {(app_badges || []).map(({ badge, link, width, margin }, i) =>
                  <Link key={i} to={link} target="_blank">
                    <Image style={{ width, margin, height: 'auto' }}>{badge}</Image>
                  </Link>
                )}
              </AppBadges>
            }
          >
            <NavItem component={Link} label="Shop" to={{ type: 'shop_director' }} />
            <NavItem component={Link} label="How it Works" to={{ type: 'how_it_works' }} />
            <NavItem component={Link} label="Get the App" to={{ type: 'get_the_app' }} />
            <NavItem component={Link} label="For Merchants" to={{ type: 'our_merchants' }} arrow />
          </NavMenu>

          <div className={styles.content} id='content'>
            {children}
          </div>

          <div className={classes(styles.footerWrapper, invertedFooter && styles.inverted)}>
            {!richTextEmpty(legal_text) && <div className={styles.footerLegalText}>
              {legal_text && <RichText className={styles.legalText} render={legal_text} />}
            </div>}
            <div className={styles.footer}>
              <div className={styles.footerLogo}>
                <span className={styles.logoWrapper}><LaybuyMono /></span>
              </div>
              <div className={styles.footerLinks}>
                {
                  (footerLinkSections && footerLinkSections.length > 0 && footerLinkSections[0].fields.length > 0) ? footerLinkSections.map((section, i) => {
                    const { primary: { section_name: sectionName }, fields: footerLinks = [] } = (section || {})
                    return (
                      <div className={this.linksOpen(sectionName, i) && styles.active} key={sectionName}>
                        <h4 onClick={this.handleOpenLinks(sectionName)}>{sectionName}</h4>
                        <ul>
                          {
                            footerLinks.map(({ link, link_text: linkText }) =>
                              <li key={linkText}>
                                <Link to={link}>{linkText}</Link>
                              </li>
                            )
                          }
                        </ul>
                      </div>
                    )
                  }) : <>
                    <div className={classes(this.linksOpen('company', 0) && styles.active)}>
                      <h4 onClick={this.handleOpenLinks('company')}>Company</h4>
                      <ul>
                        <li>
                          <Link to={{ type: 'info_page', uid: 'about' }}>About</Link>
                        </li>
                        <li>
                          <Link to={`https://help.laybuy.com/`}>Contact</Link>
                        </li>
                        <li>
                          <Link to={{ type: 'info_page', uid: 'consumer-terms' }}>Consumer Terms & Conditions</Link>
                        </li>
                        <li>
                          <Link to={{ type: 'info_page', uid: 'terms' }}>Website Terms & Conditions</Link>
                        </li>
                        <li>
                          <Link to={{ type: 'info_page', uid: 'privacy' }}>Privacy</Link>
                        </li>
                      </ul>
                    </div>
                    <div className={classes(this.linksOpen('more', 1) && styles.active)}>
                      <h4 onClick={this.handleOpenLinks('more')}>More from Laybuy</h4>
                      <ul>
                        <li>
                          <Link to={`https://help.laybuy.com/`}>Help & Support</Link>
                        </li>
                        <li>
                          <Link to={`https://help.laybuy.com/`}>FAQ</Link>
                        </li>
                        <li>
                          <Link to={`https://pages.laybuy.com/media-enquiry`}>Media</Link>
                        </li>
                        <li>
                          <Link to={`https://pages.laybuy.com/success`}>Blog</Link>
                        </li>
                        <li>
                          <Link to={`https://pages.laybuy.com/competitions`}>Competitions</Link>
                        </li>
                        <li>
                          <Link to={`https://pages.laybuy.com/${alias(pageContext.lang)}/responsible-spending`}>Responsible Spending</Link>
                        </li>
                        <li>
                          <Link to={{ type: 'info_page', uid: 'unforeseen-financial-hardship' }}>Financial Hardship</Link>
                        </li>
                      </ul>
                    </div>
                    <div className={classes(this.linksOpen('resources', 2) && styles.active)}>
                      <h4 onClick={this.handleOpenLinks('resources')}>Resources</h4>
                      <ul>
                        <li>
                          <Link to={process.env.GATSBY_MERCHANT_URL || 'https://www.laybuy.com/merchant'}>Merchant Dashboard</Link>
                        </li>
                        <li>
                          <Link to='https://docs.laybuy.com'>Developer Documentation</Link>
                        </li>
                      </ul>
                    </div>
                  </>
                }
              </div>
              <div className={styles.footerSocial}>
                <ul>
                  {(socialLinks && socialLinks.length) ? <>
                    {socialLinks.map(({ link, link_icon }, i) =>
                      <li key={i}>
                        <Link target='_blank' rel="noopener noreferrer" to={link}>
                          <Button style={{ color: "white" }}>
                            <Image>{link_icon}</Image>
                          </Button>
                        </Link>
                      </li>
                    )}
                  </> : <>
                    <li>
                      <a href='https://www.facebook.com/PaybyLaybuy/' target='_blank' rel="noopener noreferrer">
                        <Button inverted><Facebook /></Button>
                      </a>
                    </li>
                    <li>
                      <a href='https://www.instagram.com/laybuy.nz/' target='_blank' rel="noopener noreferrer">
                        <Button inverted><Instagram /></Button>
                      </a>
                    </li>
                    <li>
                      <a href='https://www.linkedin.com/company/laybuy?report%2Esuccess=KJ_KkFGTDCfMt-A7wV3Fn9Yvgwr02Kd6AZHGx4bQCDiP6-2rfP2oxyVoEQiPrcAQ7Bf' target='_blank' rel="noopener noreferrer">
                        <Button inverted><LinkedIn /></Button>
                      </a>
                    </li>
                    <li>
                      <a href='https://twitter.com/PaybyLaybuy' target='_blank' rel="noopener noreferrer">
                        <Button inverted><Twitter /></Button>
                      </a>
                    </li>
                  </>}
                </ul>
              </div>
              <div className={styles.footerSiteLinks}>
                {footer_site_links && footer_site_links.map((item) => (<Link to={item?.site_link}>{item?.site_link_text}&nbsp;↗</Link>))}
              </div>
              <div className={styles.footerLocale}>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 -960 960 960"><path d="M480-80q-84 0-157-31.5T196-197q-54-54-85-127.5T80-482q0-84 31-156.5T196-765q54-54 127-84.5T480-880q84 0 157 30.5T764-765q54 54 85 126.5T880-482q0 84-31 157.5T764-197q-54 54-127 85.5T480-80Zm0-58q35-36 58.5-82.5T577-331H384q14 60 37.5 108t58.5 85Zm-85-12q-25-38-43-82t-30-99H172q38 71 88 111.5T395-150Zm171-1q72-23 129.5-69T788-331H639q-13 54-30.5 98T566-151ZM152-391h159q-3-27-3.5-48.5T307-482q0-25 1-44.5t4-43.5H152q-7 24-9.5 43t-2.5 45q0 26 2.5 46.5T152-391Zm221 0h215q4-31 5-50.5t1-40.5q0-20-1-38.5t-5-49.5H373q-4 31-5 49.5t-1 38.5q0 21 1 40.5t5 50.5Zm275 0h160q7-24 9.5-44.5T820-482q0-26-2.5-45t-9.5-43H649q3 35 4 53.5t1 34.5q0 22-1.5 41.5T648-391Zm-10-239h150q-33-69-90.5-115T565-810q25 37 42.5 80T638-630Zm-254 0h194q-11-53-37-102.5T480-820q-32 27-54 71t-42 119Zm-212 0h151q11-54 28-96.5t43-82.5q-75 19-131 64t-91 115Z" /></svg>
                  {localeName(pageContext.lang)}
                </div>
                <div>
                  {footer_copyright_text}
                </div>
              </div>
            </div>
          </div>
          <LocaleSwitcher />
        </SiteSettingsContext.Provider>
      </PageContext.Provider>
    )
  }
}