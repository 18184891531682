import React, { useEffect, useState } from "react"
import styles from 'src/css/slices/video_hero.module.css'
import Link from 'src/components/link'
import Image from 'src/components/image'

const VideoHero = ({ primary, fields: app_badges }) => {
  const {
    poster_image_desktop,
    poster_image_mobile,
    video_url_desktop,
    video_type_desktop,
    video_url_mobile,
    video_type_mobile,
    app_qr_code,
    caption_text,
    caption_text_color,
    background_color,
  } = primary

  // We need to use Javascript to prevent both videos loading on mobile and wasting data
  const [desktopSettings, setDesktopSettings] = useState({ preload: 'none', autoPlay: false })
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setDesktopSettings({ preload: 'none', autoPlay: false })
      } else {
        setDesktopSettings({ preload: 'auto', autoPlay: true })
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    window.addEventListener('orientationchange', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize);
    }
  }, [])

  return (
    <div className={styles.wrapper} style={{ backgroundColor: background_color || '#e5d5fe' }}>
      <video {...desktopSettings} muted playsInline disableRemotePlayback loop className={styles.video} poster={poster_image_desktop?.url}>
        {video_url_desktop && <source src={video_url_desktop} type={video_type_desktop} />}
      </video>
      <video autoPlay muted playsInline disableRemotePlayback loop className={styles.mobileVideo} poster={poster_image_mobile?.url}>
        {video_url_mobile && <source src={video_url_mobile} type={video_type_mobile} />}
      </video>
      <div className={styles.text} style={{ color: caption_text_color || '#751dff' }}>
        {caption_text}
        <img src={app_qr_code?.url} alt="" />
      </div>
      {app_badges && app_badges.length > 0 && app_badges[0].badge &&
        <div className={styles.appBadges}>
          {(app_badges || []).map(({ badge, link, width, margin }, i) =>
            <Link to={link} target="_blank" className={styles.appBadge}>
              <Image style={{ width, margin, height: 'auto' }}>{badge}</Image>
            </Link>
          )}
        </div>
      }
    </div>
  )
}

export default VideoHero